<template>
  <div>
    <Header></Header>
    <div class="content">
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3">
          当前位置：<router-link to="/info">个人中心</router-link>><router-link :to="{name:'Goods_detail',query:{id:product.id}}">商品详情</router-link>><a href="javascript:;">提交订单</a>
        </div>
      </div>
      <div class="submit_order m1440">
        <div class="flex-box order_title">
          <div class="fs20 pr30">订单提交成功，请尽快付款！</div>
          <div class="fs18 flex-grow-1">订单号：{{ detail.tradeNo }}</div>
          <div class="fs16 pr20">应付总额：</div>
          <div class="flex-box flex-col-end">
            <div class="fs16 red">¥</div>
            <div class="fs28 red fwb">{{ detail.totalAmount_text?detail.totalAmount_text:detail.totalAmount }}</div>
          </div>
        </div>
        <div class="box mb30">
          <div class="title fwb fs20 pl30">商品信息</div>
          <div class="goods_info flex-box">
            <img :src="oss + product.thumbnail" class="img_about mr20" alt="" />
            <div class="flex-grow-1 flex-box flex-between flex-col-start flex-col info_detail">
              <div class="flex-box flex-between num_price">
                <div class="fs20 fwb">{{ product.productName }}</div>
                <div class="fs18">数量</div>
              </div>
              <div class="flex-box flex-between num_price">
                <div class="fs28 fwb red">
                  <span class="fs16">¥</span>{{ product.price_text?product.price_text:product.price }}
                  <span class="col9 fs16">{{ product.unit?product.unit:'' }}</span>
                </div>
                <div class="fs18">x{{ detail.totalCount_text?detail.totalCount_text:detail.totalCount }}</div>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="fs20 fwb p30">收款账号</div>
          <div  v-for="(item,index) in bankCard">
            <div class="fs18 flex-box pl30 pb20">
            <div class="flex-grow-1 saler flex-box">
              <span>开户行</span>：{{ item.bankName }}
            </div>
            <div class="flex-grow-1 saler flex-box">
              <span>账号</span>：{{ item.bankAccount }}
            </div>
          </div>
          <div class="fs18 flex-box pl30">
            <div class="flex-grow-1 saler flex-box"><span>全称</span>：{{ item.cardOwner }}</div>
          </div>
          </div>
        </div>
        <div class="pl30 fs18 fwb pb15">付款说明</div>
        <div class="pl30 fs16">
          <div v-html="content.richText"></div>
        </div>
        <div class="already_pay fs26" v-if="detail.status == 'UNPAID'" @click="payMoneyShow">
          我已付款
        </div>
      </div>
    </div>
    <Popup :visible="voucher" :confirmText="false" :cancleText="false" @close="voucherPop">
      <div class="voucher_pop">
        <div class="title">上传付款凭证</div>
        <img src="../assets/static/icon/icon_close.png" class="icon_close" alt="" @click="voucherPop">
        <div class="ptb10 plr15">
          <div class="fs16 fwb pb10">收款账号</div>
          <div v-for="(item,index) in bankCard">
            <div class="flex-box flex-between pb10 fs14 col6">
              <div class="">开户行</div>
              <div class="">{{ item.bankName }}</div>
            </div>
            <div class="flex-box flex-between pb10 fs14 col6">
              <div class="">账号</div>
              <div class="">{{ item.bankAccount }}</div>
            </div>
            <div class="flex-box flex-between pb10 fs14 col6">
              <div class="">全称</div>
              <div class="">{{ item.cardOwner }}</div>
            </div>
          </div>
          <!-- <div class="flex-box flex-between pb10 fs14 col6">
            <div class="">电话</div>
            <div class="">{{ bankCard.ownerTel }}</div>
          </div> -->
        </div>
        <div class="fs13 col6 ptb10 plr15">上传支付凭证</div>
        <div class="flex-box flex-start">
          <div class="up_box_list" v-for="(item, index) in images" :key="index">
            <img :src="oss + item" class="up_pic" alt="" />
            <img src="../assets/static/icon/icon_close.png" @click.stop="del_img(index)" class="close" alt="">
          </div>
          <div class="up_box">
            <input multiple type="file" @change="upload_img" value=""
              accept="image/png,image/jpeg,image/gif,image/jpg" />
          </div>
        </div>
        <div class="up_voucher" v-if="is_first==true" @click="payMoney">确认付款</div>
        <div class="up_voucher" v-else>付款中</div>
      </div>
    </Popup>
    <Footer></Footer>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      voucher: false,
      id: 0,
      detail: {},
      product: {},
      oss: this.global.uploadOss,
      content: "",
      bankCard: [],
      images: [],
      is_first:true
    };
  },
  watch: {
    $route() {
      let that = this;
      var code = unescape(that.$route.query.code)
      that.id = code.replace('环保桥','')
      that.getData(that.id);
    },
    deep: true,
  },
  created() {
    let that = this;
    var code = unescape(that.$route.query.code)
    that.id = code.replace('环保桥','')
    this.order_detail(this.id);
    this.get_content()
  },

  methods: {

    //上传凭证
    upload_img(e) {
      let that = this
      let loading = that.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
      })
      let formdata = new FormData();
      Array.from(e.target.files).map((item) => {
        formdata.append("file", item); //将每一个文件图片都加进formdata
      });
      axios.post(axios.defaults.baseURL + "v1/cdn/ali/upload", formdata).then((res) => {
        if (res.data.resCode == 0) {
          loading.close();
          let images = that.images;
          let data = res.data.root;
          images.push(data);
          that.images = images;
        } else {
          loading.close();
          that.$message.error(res.data.resMsg);
        }
      });
    },

    //删除图片
    del_img(index) {
      let list = this.images
      list.splice(index, 1)
      this.images = list
    },


    payMoney() {
      let that = this;
      let images = that.images;
      if (images.length <= 0) {
        that.$message.error("请上传支付凭证");
        return;
      }
      that.is_first = false;
      that.$api.apiUploadProof({ id: that.id, purchaseProof: images.join(",") })
        .then((res) => {
          that.voucher = false;
          if (res.resCode == 0) {
            that.is_first = true
            that.$message.success("提交成功,待后台审核");
            setTimeout(function () {
              that.$router.push("/my_order");
            }, 800);
          } else {
            that.is_first = true
            that.$message.error(res.resMsg);
          }
        }).catch((err) => {
          that.voucher = false;
          that.is_first = true
        });
    },
    payMoneyShow() {
      this.voucher = !this.voucher;
    },

    voucherPop() {
      this.voucher = false;
    },
    //订单详情
    order_detail(id) {
      let that = this;
      let data = {
        id: id,
        deviceType: "1",
        pkg_product_father: "com.dazz.business.model.product.Product",
      };
      that.$api.apiOrderDetail(data).then((res) => {
        if (res.resCode == 0) {
          let data = res.root[0]
          if (data.bankCard != null && data.bankCard.length > 0) {
            that.bankCard = data.bankCard;
          }
          data.product[0].price_text = that.global.addCommas(data.product[0].price)
          data.totalCount_text = that.global.addCommas(data.totalCount)
          data.totalAmount_text = that.global.addCommas(data.totalAmount)
          that.detail = data;
          that.product = data.product[0];
        } else {
          that.$message.error(res.resMsg);
        }
      });
    },

    //付款说明
    get_content() {
      let that = this;
      this.$api
        .apiLanguage({ contentKeys: "LANGUAGETEST" })
        .then((res) => {
          //富文本使用
          let data = res.root[0];
          that.content = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<!-- http://localhost:8080/submit_order?id=2 -->
